<template>
  <div style="position: relative; height: 100vh" id="htmlTest"></div>
</template>
<script>
import commonFunction from "../../mixin/commonFunction";
export default {
  name: "view_edit_survey",
  mixins: [commonFunction],
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    this.report_id = this.$route.params.url;
    document.body.classList.add("viewreporthidden");
    this.getSingleReport(this.report_id);
  },
  data() {
    return {
      report_id: null,
      url_data: null,
    };
  },
  methods: {
    getSingleReport(id) {
      var _this = this;
      var requestData = { report_id: id };
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/get-report-edit-url/" + id,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: {request_data : this.encodeAPIRequest(requestData)},
      };
      _this
        .axios(config)
        .then(({ data }) => {
          var decodedJson = _this.decodeAPIResponse(data.data);
          _this.url_data = decodedJson.edit_url;

          // Create a blob Url for hiding iframe src
          var blobMe = URL["createObjectURL"](
            new Blob([""], { type: "text/html" })
          );
          var elIframe = document["createElement"]("iframe");
          elIframe["setAttribute"]("frameborder", "0");
          elIframe["setAttribute"]("width", "100%");
          elIframe["setAttribute"]("height", "100%");
          elIframe["setAttribute"]("allowfullscreen", "true");
          elIframe["setAttribute"]("webkitallowfullscreen", "true");
          elIframe["setAttribute"]("mozallowfullscreen", "true");
          elIframe["setAttribute"](
            "style",
            "position: relative; top: 0; left: 0;"
          );
          elIframe["setAttribute"]("src", blobMe);
          var idOne = "gepa_" + Date.now();
          elIframe["setAttribute"]("id", idOne);
          document.getElementById("htmlTest").appendChild(elIframe);
          const iframeHere = _this.url_data;
          document["getElementById"](idOne)["contentWindow"]["document"].write(
            '<script type="text/javascript">location.href = "' +
              iframeHere +
              '";\x3c/script>'
          );
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          if (response.status == 500) {
            _this.$router.push({
              name: "404",
            });
          } else {
            _this.$router.push({
              name: response.status,
            });
          }

          _this.$toast.error(response.data.error, {
            position: "top-right",
            duration: 5000,
          });
        });
    },
  },
};
</script>